import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { Link } from "react-router-dom";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
export const LoggedLinks = ({ mobile, logOutUser }) => (
  <>
    <ListItem>
      <StyledTyphography
        component={Link}
        to='/blog'
        sx={{ width: mobile ? '100%' : 'max-content' }}
        variant="subtitle"
        fontSize={"1.125rem"}
        color="primary"
      >
        Entérate de lo último
      </StyledTyphography>
    </ListItem>
    <Divider />
    <ListItem>
      <StyledTyphography
        component={Link}
        to="/user-settings"
        sx={{ width: mobile ? "100%" : "max-content" }}
        variant="subtitle"
        fontSize={"1.125rem"}
        color="primary"
      >
        <PersonOutlineOutlinedIcon color="secondary" />
        Mi perfil
      </StyledTyphography>
    </ListItem>
    <Divider />
    <ListItem>
      <StyledTyphography
        onClick={logOutUser}
        component={Link}
        to="/"
        sx={{ width: mobile ? "100%" : "max-content" }}
        variant="subtitle"
        fontSize={"1.125rem"}
        color="primary"
      >
        <ExitToAppOutlinedIcon color="secondary" />
        Cerrar sesión
      </StyledTyphography>
    </ListItem>
  </>
);

export const UnLoggedLinks = ({ mobile }) => (
  <>
    <ListItem>
      <StyledTyphography
        component={Link}
        to='/blog'
        sx={{ width: mobile ? '100%' : 'max-content' }}
        variant='text'
        color='primary'
      >
        Entérate de lo último
      </StyledTyphography>
    </ListItem>
    <Divider />
    <ListItem>
      <StyledTyphography
        component={Link}
        to="/sign-in"
        sx={{ width: mobile ? "100%" : "max-content" }}
        variant="text"
        color="primary"
      >
        Ingresa
      </StyledTyphography>
    </ListItem>
    <Divider />
    <StyledListItem>
      <StyledTyphography
        component={Link}
        to="/sign-up"
        sx={{ width: mobile ? "100%" : "max-content" }}
        variant="text"
        color="primary"
      >
        Regístrate
      </StyledTyphography>
    </StyledListItem>
  </>
);

const StyledTyphography = styled(Typography)`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  :hover {
    text-decoration: underline;
  }
`;

const StyledListItem = styled(ListItem)`
  @media (min-width: 768px) {
    position: relative;
    padding: 0 25px;
    ::before {
      border-radius: 4px;
      z-index: -1;
      padding-top: 40px;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      background-color: #114c97;
    }
    > a {
      color: white;
    }
  }
`;
